import './tailwind.css';
import { createApp } from 'vue'
import App from './App.vue'
import liff from '@line/liff';
import axios from "axios";
import store from './store';
import router from './router';
import VueLazyload from 'vue-lazyload';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowRight, faMapLocation, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(faArrowRight);
library.add(faCheckCircle);
library.add(faPhone);
library.add(faMapLocation);

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
axios.defaults.headers.common["Content-Type"] =
  "application/x-www-form-urlencoded";
axios.defaults.headers.common["Access-From"] = "LIFF";
axios.defaults.headers.common.providerId = process.env.VUE_APP_DUECHAT_PROVIDER;
axios.defaults.headers.common.clientId = process.env.VUE_APP_DUECHAT_KEY;
axios.defaults.headers.common.clientSecret = process.env.VUE_APP_DUECHAT_SECRET;

// เพิ่ม Axios interceptor สำหรับเรียกใช้ accessToken จาก localStorage
axios.interceptors.request.use(config => {
  // ตรวจสอบและเพิ่ม accessToken หากมีใน localStorage
  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

async function initializeApp() {
  try {
    await liff.init({ liffId: process.env.VUE_APP_LIFF_ID });
    // สร้าง Vue instance
    const app = createApp(App);

    // ใช้ VueLazyload
    app.use(VueLazyload, {
      preLoad: 1.3,
      attempt: 1,
      listenEvents: ['scroll']
    });

    // ลงทะเบียน FontAwesomeIcon component ทั่วโกลบอล
    app.component('FontAwesomeIcon', FontAwesomeIcon);

    app.use(store).use(router).mount('#app');
  } catch (error) {
    console.error('LIFF Initialization failed', error);
  }
}

initializeApp()
